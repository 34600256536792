import React, { useState } from 'react';
import SuperPopup from '../SuperPopup';

const Install = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  const onClick = () => {
    setOpen(true);
  };
  return (
    <>
      <button onClick={onClick} className='menu__item menu__item-appinstall'>
        Install the App
      </button>
      <SuperPopup modalTitle={'Install the App'} open={open} onClose={onClose}>
        <h2 className='text-text-body text-left'>iOS</h2>
        <p className='text-text-body text-left'>
          1. Open Safari and visit HurryUp: PWAs can only be installed using
          Safari on iOS devices.
          <br />
          2. Tap the "Share" icon (the square with an upward arrow): This is
          located at the bottom center of the screen.
          <br />
          3. Select "Add to Home Screen" from the options: Scroll down until you
          see the "Add to Home Screen" option and tap it.
          <br />
          4. Name the PWA (Optional): You can rename the PWA if you prefer a
          different title. By default, it will use HurryUp.
          <br />
          5. Tap "Add": The PWA icon will now appear on your home screen.
          <br />
          6. Open the PWA from your home screen: Just tap the icon to launch the
          PWA. It will run like a native app, without the Safari browser bar.
        </p>
        <h2 className='text-text-body text-left'>Android</h2>

        <p className='text-text-body text-left'>
          1. Open your preferred browser and visit HurryUp: Most Android
          browsers support PWAs, but Google Chrome is the most common. You can
          also use Firefox or Edge.
          <br />
          2. Look for the installation prompt: In Chrome: When you load the PWA
          website, you may see a banner at the bottom of the screen saying, "Add
          to Home Screen." Tap this. If no banner appears, use the browser menu:
          Click the three vertical dots (menu) in the upper-right corner, then
          select "Add to Home screen."
          <br />
          3. Confirm installation: You'll be asked to confirm. Tap "Add" to
          place the PWA icon on your home screen.
          <br />
          4. Open the PWA from your home screen: The PWA will now appear on your
          home screen like any other app. You can open it by tapping the icon,
          and it will launch in its own window without the browser interface.
        </p>
      </SuperPopup>
    </>
  );
};

export default Install;
