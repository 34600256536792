import React from 'react';
import Modal from './Modal';
import BarLoader from 'react-spinners/BarLoader';

const SuperPopup = ({
  open,
  modalTitle,
  title,
  text,
  icon,
  onClose,
  buttons = [],
  processing = false,
  error,
  children,
  zIndex,
}) => {
  const handleClose = () => {
    if (processing) return;
    onClose();
  };

  return (
    <Modal
      open={open}
      title={modalTitle}
      onClose={handleClose}
      modalWidth='w-full max-w-[35rem]'
      contentContainerClasses='w-full text-text-body text-center px-0 md:px-8 lg:px-16 py-12'
      zIndex={zIndex}
    >
      {!!icon && (
        <img src={icon} alt='icon' className='w-[7.5rem] inline-block mb-8' />
      )}
      {!!title && (
        <h2 className='small-heading font-medium max-w-[25rem] mx-auto'>
          {title}
        </h2>
      )}

      {!!text && <p className='body-text max-w-[20rem] mx-auto'>{text}</p>}

      {buttons && buttons.length > 0 && (
        <div className='flex w-full flex-wrap justify-center mt-8'>
          {buttons.map((button, index) => (
            <button
              key={index}
              onClick={button.onClick}
              className={`button ${button.style || 'bordered'} mb-2 ${
                index === buttons.length - 1 ? '' : 'mr-2'
              }`}
              disabled={processing}
            >
              {button.text}
            </button>
          ))}
        </div>
      )}
      {processing && (
        <div className='w-full flex justify-center mt-2'>
          <BarLoader />
        </div>
      )}
      {error && (
        <div className='body-text text-text-grey-600 mt-4'>{error}</div>
      )}

      {!!children && <div className='mt-8'>{children}</div>}
    </Modal>
  );
};

export default SuperPopup;
